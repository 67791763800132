import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateDocument, { TblUpdateDocument, Value } from '../model/tblUpdateDocument';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TblUpdateSupplier');
export interface BackendTblUpdateDocument {  
  getTblUpdateDocuments: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getCountWerbungDocument:(searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getAddDocumentCompleted(payload: any): AxiosPromise<any>;
  deleteTblUpdateDocument(id: string): AxiosPromise;
  updateTblUpdateSupplier(TblUpdateSupplier: TblUpdateDocument): AxiosPromise<any>;
  getCanselDocumentCompleted(payload: any): AxiosPromise<any>;
}

export const defaultBackendTblUpdateDocument: BackendTblUpdateDocument = {
  getTblUpdateDocuments(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateDocumentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTblUpdateDocuments${url}`);
    if (searchParams.filter?.length != 0)
    {
        url=url.replace("contains(tolower(doc_changedate),","contains(tolower(cast(doc_changedate, 'Edm.String')),");
        url=url.replace("contains(tolower(documentDoneTimeCompleted),","contains(tolower(cast(documentDoneTimeCompleted, 'Edm.String')),");
    }
    return instance.put<Value>(url, searchData);
  },
  getCountWerbungDocument(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateDocumentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    return instance.put<Value>(url, searchData);
  },
  deleteTblUpdateDocument(id: string): AxiosPromise {
    logger.debug('deleteTblUpdateDocument');
    return instance.delete(`${URLS.tblUpdateDocument}/${id}`);
  },
  updateTblUpdateSupplier(TblUpdateDocument: TblUpdateDocument): AxiosPromise<any> {
    logger.debug('updateTblUpdateDocument');
    return instance.put<TblUpdateDocument>(
      `${URLS.tblUpdateDocument}/update`,
      tblUpdateDocument.toAPI(TblUpdateDocument)
    );
  },
  getAddDocumentCompleted( data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateDocument}/AddDocumentCompleted`, data);
  },
  getCanselDocumentCompleted( data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateDocument}/CanselDocumentCompleted`, data);
  },
};
