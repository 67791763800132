import gspUserRoleDetail, { GspUserRoleDetail } from './gspUserRoleDetail';

export interface GspUserRole {
  id: number; // int
  contactId?: string; // ulong
  contactEmail: string;
  contact: any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.ContactDm

  zrNumRolesData: string;
  //roles: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspRole>
  created: string; // System.DateTime?
  creatorId: string; // string
  creatorEmail: string; // string
  gspUserRoleDetails: GspUserRoleDetail[];
}

// [{"moduleRoles": [{"id": 1, "name": "Verband,Einkauf,ZR,DST,Unsere Marken,Schulung,Chef,Indiv_Dok,Trendhopper,Roomio,Roomio_Verkauf,Roomio_Marketing,Roomio_Einkauf,Roomio_Inhaber,Trendhopper_Verkauf,Trendhopper_Marketing,Trendhopper_Einkauf,Trendhopper_Inhaber", "roles": [1, 2, 3, 4], "isCommon": true}], "zrNummerAll": "300020"}]
export interface UserActiveServices {
  contactId: number;
  countRight: number;
}

export interface Value {
  value: GspUserRole[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspUserRole>): any {
  return {
    Id: data?.id || 0,
    ContactId: data?.contactId || undefined,
    ContactEmail: data?.contactEmail || '',
    Contact: data?.contact || undefined,
    ZrNumRolesData: data?.zrNumRolesData || '',
    // Roles: data?.roles || '',
    Created: data?.created || '',
    CreatorId: data?.creatorId || '',
    CreatorEmail: data?.creatorEmail || '',
    GspUserRoleDetails: data?.gspUserRoleDetails || undefined,
  };
}

function parse(data?: Partial<GspUserRole>): GspUserRole {
  return {
    id: data?.id || 0,
    contactId: data?.contactId || undefined,
    contactEmail: data?.contactEmail || '',
    contact: data?.contact || '',
    zrNumRolesData: data?.zrNumRolesData || '',
    // roles: data?.roles || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    gspUserRoleDetails: data?.gspUserRoleDetails?.map((x) => gspUserRoleDetail.parse(x)) || [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
