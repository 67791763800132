import { OdataItems } from '@/shared/model/OdataItems';
import { Value, UserActionsUserRoleChanges } from '@/shared/model/userActionsUserRoleChanges';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VDivider } from 'vuetify/lib';
import ChangeLogInfoTableDialog from './change-log-info-table/change-log-info-table.vue';
import { SearchParams } from '@/shared/model/searchParams';

const userActionsUserRoleChangesModule = namespace('userActionsUserRoleChanges');

@Component({ components: { VDivider, ChangeLogInfoTableDialog } })
export default class ChangeLogInfo extends Vue {
  @userActionsUserRoleChangesModule.Action('getUserActionsUserRoleChanges')
  private actionGetUserActionsUserRoleChanges!: (contactId: string) => Promise<Value>;
  @userActionsUserRoleChangesModule.Mutation('resetUserActionsUserRoleChangesDataOption')
  private mutationResetUserRoleChangesDataOption!: () => void;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChanges')
  private userActionsUserRoleChanges!: OdataItems<UserActionsUserRoleChanges>;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChangesSearchParams')
  private userLogSearchParams!: SearchParams;

  @Prop()
  private contactId!: string;

  private changeLogInfoTableDialog: { show: boolean } = { show: false };

  private async getUserActionsUserRoleChanges() {
    //this.mutationResetUserRoleChangesDataOption();
    await this.actionGetUserActionsUserRoleChanges(this.contactId).then(async (response: any) => {
      this.mutationResetUserRoleChangesDataOption();
      console.log('111111111111111111111111111111111111---------------mutationResetUserRoleChangesDataOption---------------user ActionsUserRoleChanges------------------------------  ===>>>   ', this.userActionsUserRoleChanges);
    });;
  }

  private onMouseOver() {
    this.getUserActionsUserRoleChanges();
    console.log('111111111111111111111111111111111111------------------------------user ActionsUserRoleChanges------------------------------  ===>>>   ', this.userActionsUserRoleChanges);
  }

  private onClickIcon() {
    this.changeLogInfoTableDialog.show = true;
  }

  private onDialogClose() {
    this.changeLogInfoTableDialog.show = false;
  }

  private getLogEntryInfoText(entry: UserActionsUserRoleChanges) {
    let result:any = '';
    console.log('""""""""""""""""""""""""""""""""""""""""""""""""""""""""------------------------ge tLogEntryInfoText------------------------------------  ===>>> entry contact_id ', entry.contact_id);
    switch (entry.user_action_type) {
      case 'Add':
        result = this.$t('role_added', {
          role: entry.role_name,
          service: entry.service_name,
          email: entry.erstellt_von_email,
        });
        break;
      case 'Delete':
        result = this.$t('role_removed', {
          role: entry.role_name,
          service: entry.service_name,
          email: entry.erstellt_von_email,
        });
        break;
      case 'DeleteAll':
        result =  this.$t('roles_removed', { roles: entry.role_name, email: entry.erstellt_von_email });
        break;
      case 'AddStatus':
        result =  this.$t('status_added', {
          status: this.$tc(`status.${entry.role_name}`),
          email: entry.erstellt_von_email,
        });
        break;
      case 'RemoveStatus':
        result =  this.$t('status_removed', {
          status: this.$tc(`status.${entry.role_name}`),
          email: entry.erstellt_von_email,
        });
        break;
    }
    if (!!entry.zr_nummer && (entry.user_action_type == 'Add' || entry.user_action_type == 'Delete')) {
      result = `${this.$t('module_word')} "${entry.module_name}" (ZR: ${entry.zr_nummer}) ${result}`
    }
    // console.log('result :>> ', result);
    return result;
  }
}
