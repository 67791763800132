import GeneralUtils from './generalUtils';

export type AzureUserType = 'Admin' | 'Support' | 'User' ;

export default class AuthUtils {

  public static getTenantId() {
    let tenantId =
      GeneralUtils.isDevEnvironment() || this.isUrlInclude('dev4you')
        ?  GeneralUtils.isLocalDevEnv() ? '9d3a2809-e0a2-4cfd-b2ce-bd540b6da009' : '2775340b-b251-401c-bcac-e05e5f499a3b'
        : 'f606a097-2d4d-4038-97f3-4287834b31bf'; // Azure tenantId is the same both for multiple `clienId`
    return tenantId;
  }

  public static getClientId() {
    let clientId =
      GeneralUtils.isDevEnvironment() || this.isUrlInclude('dev4you')
        ? GeneralUtils.isLocalDevEnv() ? '591cf799-7461-4f0b-bc5f-5044e5684126' : '5ee51967-6ecb-4bea-b004-dbd1b766afe5'
        : this.isUrlInclude('staging') ?  '8ed01024-52b4-4843-a02c-0a5f6f72945b'
                : this.isUrlInclude('admpnl.meinvme.de') ? '7c241376-7aa4-41e6-8308-32957bfb5f73' : 'NO ID' ;
                //        `10210597-b03a-461a-8082-f0853a78b5b2` for Staging env (staging.zr-gs.meinvme.de until 2024-09)

              // URLs:
              // Staging: https://staging.admpnl.meinvme.de/
              // Prod: https://admpnl.meinvme.de/
    return clientId;
  }
  
// -PROD- data
 // aad-app-admpnl-vmesupport-prod; Group-ID: 496adf28-cb09-4ad9-8f2b-35e4a3428281
// aad-app-admpnl-vmeadmin-prod; Group-ID: 80eee2f3-d108-4cf3-8505-d93d9a698fef
// aad-app-admpnl-gsadmin-prod; Group-ID: 3193d9d0-36c4-4e31-ae6e-c011405301e9
// -localDev- data
// `vme-admin` ID: `b9d13f54-7fe5-452c-8b1d-24db1e8c1875`

  // Default `false` mean user will have `Admin` role locally
  public static isForceOnlyGsUser = false; // by default user is Admin+Support change to `true` to be in ONLY 1 role `GsUser` 

  public static getVmeGlobalAdminGroup() {
    let groupId = GeneralUtils.isLocalDevEnv() ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875' : '80eee2f3-d108-4cf3-8505-d93d9a698fef' ;
    let name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-admpnl-vmeadmin-prod' ;

    if (GeneralUtils.isLocalDevEnv() && this.isForceOnlyGsUser) {
      name = 'vme-admin33'; // can put ANY value except `vme-admin`
      groupId = 'vme-admin33';
    }

    return {name, groupId};
  }
  public static getVmeSupportGroup() {
    let groupId = GeneralUtils.isLocalDevEnv() ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875' : '496adf28-cb09-4ad9-8f2b-35e4a3428281' ;
    let name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-admpnl-vmesupport-prod' ;

    if (GeneralUtils.isLocalDevEnv() && this.isForceOnlyGsUser) {
      name = 'vme-admin33'; // can put ANY value except `vme-admin`
      groupId = 'vme-admin33';
    }

    return {name, groupId};
  }
  public static getVmeSingleCompanyAdminGroup() {
    const groupId = GeneralUtils.isLocalDevEnv() ? 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875' : '3193d9d0-36c4-4e31-ae6e-c011405301e9' ;
    const name = GeneralUtils.isLocalDevEnv() ? 'vme-admin' : 'aad-app-admpnl-gsadmin-prod' ;

    return {name, groupId};
  }



  public static isUrlInclude(value: string): boolean {
    let result = window.location.host.toLowerCase().includes(value);
    return result;
  }
}
