export enum FilterContactType {
  HouseAdmin = 'HouseAdmin',
}

export interface SearchData {
  isActive: boolean | null;
  isMeinVme: boolean | null;
  isIntern: boolean | false;
  isInitialRequest: boolean; // (AD-139) `isInitialRequest` needed to load all contacts, to get all roles for them on 1st request, this field will be `NULL` (not sent) on other (than initial) requests
  companyZr: string[];
  companyZrString: string; // (AD-173) see comment below for  `contactIdsString`
  isUserSupportOrSuperAdmin: boolean;
  contactIds: string[] | null;
  contactIdsString: string; // (AD-173) need it for `Watcher` to see the change in `SearchData` when filter by `Role` since above `contactIds` is Reference type and has the SAME value in `Old/New` values in `Watcher`
  contactTypes: FilterContactType[] | null;
  contactTypesString: string; // (AD-173) see comment above for  `contactIdsString`
  lang?: string;
  contact_Type?: string;
}

function defaultData(): SearchData {
  return {
    isActive: true,
    isMeinVme: null,
    isIntern: false,
    isInitialRequest: true,
    companyZr: [],
    companyZrString: '',
    isUserSupportOrSuperAdmin: false,
    contactIds: null,
    contactIdsString: '',
    contactTypes: null,
    contactTypesString: '',
    contact_Type: "" // (AD-151) without default value extra query is sent in `contact-list` (`onOptionsFilterChanged` function)
  };
}

export default {
  defaultData,
};
