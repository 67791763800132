
import { GspUserRoleState } from './types';

export const state = (): GspUserRoleState => initialState();

export const initialState = (): GspUserRoleState => ({
  gspUserActiveServicesList: [],
  gspUserRolesAllForAdmin: {
    items: [],
    total: 0,
    isLoading: false,
  },
  gspUserRolesActiveAndNot: {
    items: [],
    total: 0,
    isLoading: false,
  },
  gspUserRoles: {
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 5000,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },

  },
});
