
import { ActionTree } from 'vuex';
import { GspServiceState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspService } from '@/shared/backend/gspService';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspService, { Value, GspService } from '@/shared/model/gspService';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { ContactType } from '@/shared/model/gspRole';

const logger = new Logger('actions.gspServices');
export const actions: ActionTree<GspServiceState, RootState> = {
  getGspServices({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, contactType?: any }) {
    commit('setGspServicesIsLoading', true);

    let contactType:ContactType | undefined = payload?.contactType === undefined || payload?.contactType === null ? undefined :
    payload?.contactType == 'supplier' ? 'Lieferanten' : 'Mitglieder';

    let searchParams = payload?.searchParams ?? getters.getGspServicesSearchParams;
    return defaultBackendGspService
      .getGspServices(searchParams, contactType)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspServices', response.data);
        commit('setGspServicesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspServicesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getGspServicesForContacts({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, contactType?: any }) {
    commit('setGspServicesForContactsIsLoading', true);

    let contactType:ContactType | undefined = payload?.contactType === undefined || payload?.contactType === null ? undefined :
    payload?.contactType == 'supplier' ? 'Lieferanten' :  payload?.contactType == 'intern' ? 'VME-intern' : 'Mitglieder';

    let searchParams = payload?.searchParams ?? getters.getGspServicesSearchParams;
    return defaultBackendGspService
      .getGspServicesForContacts(searchParams, contactType)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspServicesForContacts', response.data);
        commit('setGspServicesForContactsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspServicesForContactsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGspService({ commit, dispatch }, id: string) {
    return defaultBackendGspService
      .getGspService(id)
      .then((response: AxiosResponse<GspService>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspService({ commit, dispatch }, file: GspService) {
    return defaultBackendGspService
      .updateGspService(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspService;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspService_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspService({ commit, dispatch }, id: string) {
    return defaultBackendGspService
      .deleteGspService(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspService;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateServiceCommonText({ commit, dispatch }, payload: any) {
    commit('setServiceCommonText', payload);
  }
};
