
import { GetterTree } from 'vuex';
import { GspServiceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspServiceState, RootState> = {
  ['getGspServices']: (state) => state.gspServices,
  ['getGspServicesIsLoading']: (state) => state.gspServices?.isLoading,
  ['getGspServicesTotal']: (state) => state.gspServices?.total,
  ['getGspServicesSearchParams']: (state) => state.gspServices?.searchParams,

  ['getGspServicesForContacts']: (state) => state.gspServicesForContacts,
  ['getGspServicesForContactsIsLoading']: (state) => state.gspServicesForContactsIsLoading,
};
