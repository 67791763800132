/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { Contact } from '@/shared/model/contact';
import ContactPanels from './contact-panel/contact-panel.vue';
import { OdataItems } from '@/shared/model/OdataItems';
import { GspUserRole, UserActiveServices } from '@/shared/model/gspUserRole';
import { VChip } from 'vuetify/lib';
import ChangeLogInfo from './change-log-info/change-log-info.vue';
import { GspUserRoleDetailInner } from '@/shared/model/gspUserRoleDetail';

const logger = new Logger('contact');
const contactModule = namespace('contact');
const contactPanelModule = namespace('contactPanel');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');

@Component({
  components: { ContactPanels, VChip, ChangeLogInfo },
})
export default class ContactView extends Vue {
  @contactModule.Action('updateContactRights')
  private actionUpdateContactRights!: any;
  @contactPanelModule.Action('deleteContactPanel')
  private actionDeleteContactPanel!: any;
  @contactModule.Action('countHouseAdminsInZrCluster')
  private actionCountHouseAdminsInZrCluster!: (contact: Contact) => Promise<number>;

  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;

  // @contactModule.Action('countActiveServices')
  // private actionCountActiveServices!: (contactId: number) => Promise<number>;


  @contactPanelModule.Action('updateContactPanel')
  private actionUpdateContactPanel!: any;
  @contactPanelModule.Action('updateContactPanelKatalogwand')
  private actionUpdateContactPanelKatalogwand!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: OdataItems<GspUserRole>;


  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: any;

  // @gspUserRoleModule.Action('getUserActiveServices')
  // private actionUserActiveServices!: any;
  @gspUserRoleModule.Getter('getUserActiveServices')
  private getUserActiveServices!: UserActiveServices[];

  @Prop()
  private contact!: Contact;

  private readonly maxHouseAdminsPerZrCluster = 3;
  private zrbi = false;

  get loggedInUserContactVme() {
    return this.contactForLoggedInUser?.istILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen;
  }

  mounted() {
    // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // console.log('mounted userRole result :>> ', result);
    // console.log('mounted  contact:>> ', this.contact.email);
    if (this.userRole?.zrNumRolesData) {
      this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);

      this.userRoleData.forEach((element: any, index) => {
        if (element?.zrNummer) {
          Vue.set(this.boxes, index, true);
          Vue.set(this.roleBoxes, index, element.roles);
        }
      });
      this.userRolesPayload = this.userRoleData;
    }
    console.log('********************************************************************************---------------------------mounted---------------------------------  ===>>> get UserActiveServices  ', this.getUserActiveServices);
  }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  get zrNumberArr() {
    return this.contact.companyZrNumber?.split(',') || [];
  }

  get isSupplierUrl() {
    return this.$route.fullPath.includes('/supplier');
  }

  get userRole() {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--------CONTACT-----------------user Role-----------------------------------  ===>>> this.userRoles  ', this.userRoles, '   ');
    // (AD-143) possible `email` duplicates (1 active and 1 inactive `Contacts` with the same `email`), so decided to use both `recordID` + `email`
    let result = this.userRoles.items.find((role: any) => role.contactId == this.contact.recordID); // (AD-174) now filter `GspRole` ONLY by ContactId (`RecordID`)
    // if (!result) {
    //   result = this.userRoles.items.find((role: any) => role.contactEmail.toLowerCase() == this.contact.email.toLowerCase()); 
    // }
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--------CONTACT-----------------user Role-----------------------------------  ===>>> result  ', result, '   ');
    return result;
  }

  get roles() {
    return this.gspRoles.items;
  }
  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    const payload = {
      id: this.userRole?.id || undefined,
      contactId: this.contact.recordID,
      contactEmail: this.contact.email,
      zrNumRolesData: JSON.stringify(this.userRolesPayload),
    };
    console.log('payload :>> ', payload);
    console.log('!@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@-------------------------sa------ve-----------------------------------  ===>>>  payload ', payload);
    this.actionUpdateGspUserRole(payload);
  }
  //#endregion

  //#region (AD-67) Condition for `IL-KPI` checkbox
  private multipleValuesToken = [',', ';'];

  private isSingleIlDashboardAzureServiceID =
    !!this.contact.ilDashboardAzureServiceID &&
    this.multipleValuesToken.every((token) => !this.contact.ilDashboardAzureServiceID.includes(token));

  get isCompanyInAzureService(): boolean {
    let condition = false;
    if (this.isSingleIlDashboardAzureServiceID) {
      condition = this.contact.azureServices.includes(this.contact.ilDashboardAzureServiceID);
    } else {
      const dashboardAzureServices = this.contact.ilDashboardAzureServiceID.split(/[;,]+/); // use multiple separators, just in case

      condition = dashboardAzureServices.some((companyAzureServiceId) =>
        this.contact.azureServices.includes(companyAzureServiceId.trim())
      );
    }

    return !!this.contact.ilDashboardAzureServiceID && condition;
  }
  //#endregion

  get isKatalogwand() {
    return this.contact?.azureServices?.includes('9360b61c-1af1-487a-970f-3872b2b3265c');
  }

  get isLoggedInUserEmail(): boolean {
    return this.contact.email === this.contactForLoggedInUser?.email;
  }

  // (2024-04) THIS logic outdated by customer word
  // get rightsCount(): number {
  //   const sum = this.isCompanyInAzureService ? 1 : 0;
  //   const sum2 = this.contact.isZrBiActive ? 1 : 0;
  //   const sum3 = this.isKatalogwand ? 1 : 0;

  //   return sum + sum2 + sum3;
  // }

  get rightsCount(): number {
    console.log('!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@----------------------------rights Count--------------------------------  ===>>>  this.userRole? ', this.userRole);
    let gspUserRoleDetailsCount = this.userRole?.gspUserRoleDetails?.length || 0;
    if (gspUserRoleDetailsCount) {
      //console.log('this.userRole?.gspUserRoleDetails :>> ', this.userRole?.gspUserRoleDetails);
      gspUserRoleDetailsCount =
        this.userRole?.gspUserRoleDetails?.filter(
          (x) =>
            typeof x.roleDetails == 'object' &&
            (x.roleDetails as GspUserRoleDetailInner[]).find((y) => y.moduleRoles?.find((z) => z.roles?.length))
        ).length ?? 0;
    }
    console.log('!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@----------------------------rights Count--------------------------------  ===>>>  gspUserRoleDetailsCount ', gspUserRoleDetailsCount);
    return gspUserRoleDetailsCount;
  }

  CountServeces(contactId: any) : any
  {
    console.log('+++++++++++++++++++++++++++++++++++++CountServeces----------------------------rights Count--------------------------------  ===>>>  contactId ', contactId);
   //let rightCount = this.actionCountActiveServices(contactId);
   let result : any = 0;
   result = this.getUserActiveServices.find((x: any) => x.contactID == contactId)?.countRight;   
   if (result == undefined)
   {
    result = 0;
   }
   console.log('+++++++++++++++++++++++++++++++++++++CountServeces----------------------------rights Count--------------------------------  ===>>>  result ', result);
   return result;
  }

  changeActiveSwitch(): void {
    if (this.isLoggedInUserEmail) {
      return;
    }
    this.$emit('update:active', this.contact);
  }

  async changeHouseAdminSwitch(): Promise<void> {
    if (this.isLoggedInUserEmail) {
      return;
    }

    // If the switch has been set to true, check if the new amount does not surpass
    // the allowed amount of house admins in the zr cluster of this contact.
    if (this.contact.isHouseAdmin) {
      let currentAmount = await this.actionCountHouseAdminsInZrCluster(this.contact);
      if (currentAmount >= this.maxHouseAdminsPerZrCluster) {
        this.contact.isHouseAdmin = false;
        this.$message.open(
          this.$tc('max_house_admin_count_reached_title'),
          this.$tc('max_house_admin_count_reached_text'),
          { hasCancelBtn: false, okText: this.$t('max_house_admin_count_reached_ok') }
        );
        return;
      }
    }

    this.$emit('update:is-house-admin', this.contact);
  }

  changeZrBiCheckbox(event: any): void {
    //const box = this.$el.querySelector<any>('.zr-bi__checkbox [type="checkbox"]');
    // console.log('box :>> ', box?.checked); // get real checkbox input value
    //const checkboxValue = box?.checked;

    const checkboxValueBeforeClick = this.contact.isZrBiActive;

    // show dialog on label click if checkbox checked(TRUE) otherwise ignore
    if (event.target.className.includes('zr-bi__label')) {
      // if label clicked finish this method and let the `zrBiLabelClick` method handle label click event
      return;
    }

    if (!checkboxValueBeforeClick) {
      this.showDocDialog();
    } else {
      event.preventDefault();
      event.stopPropagation();

      this.showDeleteConfirmation();
    }
  }

  zrBiLabelClick(event: any): void {
    logger.log('zrBiLabelClick event :>> ', event);
    // console.log('this.contact.isZrBiActive :>> ', this.contact.isZrBiActive);
    if (this.contact.isZrBiActive) {
      this.showDocDialog();
    }
    event.preventDefault();
  }

  showDeleteConfirmation(): void {
    // (AD-67) should not delete `ContactPanel` but clear `ZR-BI` fields
    this.$confirm
      .open(`${this.$t('zrbi_dialog_title')}`, `${this.$t('zrbi_dialog_text')}`, {
        cancelText: this.$t('zrbi_dialog_cancel'),
        okText: this.$t('zrbi_dialog_ok'),
      })
      .then(async (response: any) => {
        logger.log('before IF response askDialog :>> ', response);
        if (response) {
          logger.log('contactPanelId :>> ', this.contact.contactPanelId);
          if (this.contact.contactPanelId) {
            const contactPanelPayload = {
              id: this.contact.contactPanelId,
              email: this.contact.email,
              isKpiChangeMode: false, // send from dialog related to `ZR-BI`
            };

            this.actionUpdateContactPanel(contactPanelPayload)
              .then((result: any) => {
                this.contact.isZrBiActive = result.isZrBiActive;
                logger.log('actionUpdateContactPanel ZR-BI result :>> ', result);
              })
              .catch((err: any) => {
                logger.error(err);
              });
          }
        } else {
          this.contact.isZrBiActive = true;
        }
      });
  }

  showDocDialog(): void {
    logger.log('showDocDialog :>> ');
    this.$emit('show:dialog', this.contact);
  }

  changeKatalogwand(wandValue: boolean): void {
    const azureServicesChecked = wandValue
      ? `${this.contact.azureServices ? this.contact.azureServices + ';' : ''}9360b61c-1af1-487a-970f-3872b2b3265c`
      : this.contact.azureServices.replace('9360b61c-1af1-487a-970f-3872b2b3265c', ';').replace(';;', '');

    const payload = {
      RecordID: this.contact.recordID,
      azureServices: azureServicesChecked,
    };

    this.actionUpdateContactRights(payload)
      .then((result: any) => {
        logger.log('actionUpdateContactRights result :>>', result);
        this.contact.azureServices = azureServicesChecked;

        const contactPanelPayload = {
          id: this.contact.contactPanelId,
          email: this.contact.email,
          isKpiChangeMode: false,
          katalogwand: wandValue,
        };

        this.actionUpdateContactPanelKatalogwand(contactPanelPayload).then((result: any) => {
          this.contact.contactPanelId = result.id;
          logger.log('actionUpdateContactPanel Katalogwand result :>> ', result);
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  changeKpi(kpiValue: boolean): void {
    const azureServicesChecked = this.getCorrectAzureServices(kpiValue);

    const payload = {
      RecordID: this.contact.recordID,
      isKpi: kpiValue,
      azureServices: azureServicesChecked,
    };

    this.actionUpdateContactRights(payload)
      .then((result: any) => {
        logger.log('actionUpdateContactRights result :>>', result);
        this.contact.azureServices = azureServicesChecked;

        const contactPanelPayload = {
          id: this.contact.contactPanelId,
          email: this.contact.email,
          isIlKpiActive: kpiValue,
          isKpiChangeMode: true,
        };

        this.actionUpdateContactPanel(contactPanelPayload).then((result: any) => {
          this.contact.contactPanelId = result.id;
          logger.log('actionUpdateContactPanel IL-KPI result :>> ', result);
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  getCorrectAzureServices(ilKpiValue: boolean): string {
    const originalAzureServices = this.contact.azureServices;
    let ilDashboardAzureServiceID = this.contact.ilDashboardAzureServiceID;
    const isMultipleCompanyAzureServiceID = this.multipleValuesToken.some((token) =>
      ilDashboardAzureServiceID.includes(token)
    );
    const contactHasAzureServices = !!originalAzureServices.trim();
    let result = '';

    // if `ilDashboardAzureServiceID` is null or empty do nothing (just use old original Contact `azureServices`)
    if (!ilDashboardAzureServiceID) {
      result = originalAzureServices;
    }
    // if single `ilDashboardAzureServiceID` continue, otherwise split it into array of ilDashboardAzureServiceIDs
    ilDashboardAzureServiceID = isMultipleCompanyAzureServiceID
      ? ilDashboardAzureServiceID
          .split(',')
          .map((x: string) => x.trim())
          .join(';')
      : ilDashboardAzureServiceID;

    if (!contactHasAzureServices) {
      result = ilDashboardAzureServiceID;
    }

    if (contactHasAzureServices && ilKpiValue) {
      result = `${originalAzureServices};${ilDashboardAzureServiceID}`;
    } else if (contactHasAzureServices && !ilKpiValue) {
      let contactAzureServicesIds = originalAzureServices.split(';');
      const dashboardAzureServicesIds = ilDashboardAzureServiceID.split(';');

      // remove `dashboardAzureServicesIds` from Contact `azureServices`
      contactAzureServicesIds = contactAzureServicesIds.filter((x) => !dashboardAzureServicesIds.includes(x));
      result = contactAzureServicesIds.join(';');
    }

    return result;
  }
}
