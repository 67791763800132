
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspService, { GspService, Value } from '../model/gspService';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
import { ContactType } from '../model/gspRole';

const logger = new Logger('backend.GspService');
export interface BackendGspService {
  getGspService(id: string): AxiosPromise<GspService>;
  getGspServices: (searchParams: SearchParams, contactType: ContactType | undefined | '') => AxiosPromise<Value>;
  getGspServicesForContacts: (searchParams: SearchParams, contactType: ContactType | undefined | '') => AxiosPromise<Value>;
  deleteGspService(id: string): AxiosPromise;
  updateGspService(GspService: GspService): AxiosPromise<any>;
}

export const defaultBackendGspService: BackendGspService = {
  getGspService(id: string): AxiosPromise<GspService> {
    let url = `${URLS.gspService}/${id}`;
    return instance.get<GspService>(url);
  },
  getGspServices(searchParams: SearchParams, contactType: ContactType | '' = ''): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspServiceOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=Modules($select=id,name,isCommon)'] // when try to `$filter` on `contact_types` always get backend error, since Odata badly support search on `many-to-many` relationship (filter on backend)
    );
    logger.log(`getGspServices${url}`);
    return instance.get<Value>(url, { headers: { contactType: contactType+'' } });
  },
  getGspServicesForContacts(searchParams: SearchParams, contactType: ContactType | '' = ''): AxiosPromise<Value> {
    return instance.get<Value>(`${URLS.gspService}/GetServicesForContacts`, { headers: { contactType: contactType+'' } });
  },

  deleteGspService(id: string): AxiosPromise {
    logger.debug('deleteGspService');
    return instance.delete(`${URLS.gspService}/${id}`);
  },
  updateGspService(GspService: GspService): AxiosPromise<any> {
    logger.debug('updateGspService');
    return instance.put<GspService>(`${URLS.gspService}/update`, gspService.toAPI(GspService));
  },
};
