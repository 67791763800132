import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/home/Home.vue';
import { ROUTES } from './routesEnum';
import { Logger } from 'fsts';
import AuthorityUtils from '@/router/authorityUtils';

const logger = new Logger('router');
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: ROUTES.home,
    component: Home,
    // if uncomment then on login redirect to `/login` URL
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/suppliers',
    name: ROUTES.suppliers,
    component: Home,
    // if uncomment then on login redirect to `/login` URL
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/intern',
    name: ROUTES.intern,
    component: Home,
    // if uncomment then on login redirect to `/login` URL
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
  },
  {
    path: '/vmesupplier',
    name: ROUTES.vmeSupplier,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-supplier-temp/vme-supplier-temp.vue'),
  },
  {
    path: '/vmekollege',
    name: ROUTES.vmeKollege,
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-iframe/vme-iframe.vue'),
  },
  {
    path: '/vmeblock',
    //alias: '/blockverrechnung',
    name: ROUTES.vmeBlock,
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-iframe/vme-iframe.vue'),
  },
  {
    path: '/vmeumsatz',
    name: ROUTES.vmeUmsatz,
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-iframe/vme-iframe.vue'),
  },
  {
    path: '/vmeUmsatzvergleich',
    name: ROUTES.vmeUmsatzvergleich,
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-iframe/vme-iframe.vue'),
  },
  {
    path: '/vmeUmsatzanzeige',
    name: ROUTES.vmeUmsatzanzeige,
    component: () => import(/* webpackChunkName: "about" */ '../views/home/vme-iframe/vme-iframe.vue'),
  },
  {
    path: '/tasks',
    name: ROUTES.tasks,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tasks-dashboard.vue'),
  },
  {
    path: '/tasks/suppliers',
    name: ROUTES.tasks_suppliers,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tblUpdateSuppliers/tblUpdateSuppliers.vue'),
  },
  {
    path: '/tasks/conditions',
    name: ROUTES.tasks_conditions,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tblUpdateConditions/tblUpdateConditions.vue'),
  },
  {
    path: '/tasks/documents',
    name: ROUTES.tasks_documents,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tblUpdateDocuments/tblUpdateDocuments.vue'),
  },
  {
    path: '/tasks/werbung',
    name: ROUTES.werbung,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tblUpdateDocuments/tblUpdateDocuments.vue'),
  },
  {
    path: '/tasks/news',
    name: ROUTES.news,
    component: () => import(/* webpackChunkName: "about" */ '../views/tasks/tblUpdateNews/tblUpdateNews.vue'),
  },
  {
    path: '/login',
    name: ROUTES.login,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home/login/login.vue'),
  },
  {
    path: '/roles',
    name: ROUTES.gspRoles,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/roles-services/gspRoles.vue'),
  },
  {
    path: '/role/:gspRoleId',
    name: ROUTES.gspRole,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/roles-services/gspRole/gspRole.vue'),
  },
  // {
  //   path: '/list/:saveListId',
  //   name: ROUTES.list,
  //   component: () => import(/* webpackChunkName: "save-lists" */ '../views/save-lists/save-list/save-list.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Handle role based guarding of routes.
 */
router.beforeEach(async (to: any, from: any, next: any) => {
  // always load api information
  logger.debug(`beforeEach from:${from.path} to:${to.path}`);
  //if route to login then just router does not make any other actions
  if (to.path == 'login') {
    logger.debug('path==login');
    next();
    return;
  }

  // does the route require authorization? if not then just route do not make any other actions
  if (!to.matched.some((record: any) => record.meta.requiresAuth)) {
    logger.debug(`!requiresAuth to:${to.path}`);
    next();
    return;
  }

  try {
    // enforce loading of account details
    // await AuthorityUtils.ensureAccountDetails(); // don't need it for now with AzureAd

    //user is loggedin
    if (!AuthorityUtils.isEmptyAccount() || !AuthorityUtils.isEmptyAzureAccount()) {
      logger.debug('isLoggedin');
      // check access to route based on roles
      // if (
      //   !AuthorityUtils.isAdmin() &&
      //   !AuthorityUtils.hasAnyRole(to.meta.requiresRole)
      // ) {
      //   logger.debug('!isAdmin && !hasAnyRole');
      //   next('error-access-view');
      //   return;
      // }

      logger.debug(`from:${from.path},to:${to.path}`);
      next(); // all is fine
      return;
    }
  } catch (e) {
    logger.error('error');
  }
  next({
    path: '/login',
    query: { redirect: to.fullPath },
  });
});

export default router;
