export type ContactType = 'Mitglieder' | 'Lieferanten' | 'VME-intern' ; // Member | Supplier | VME-internal

export interface GspRole {
  id: number; // int
  name: string; // string
  description: string; // string

  rolesData: { serviceId: number; service: { name: string } }[]; // ICollection<GspRoleDataDm>
  rolesDataServiceIds: string; // !! only frontend calculated field
  services: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspServiceDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspServiceDm>
  userRoles: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspUserRoleDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspUserRoleDm>
  zrNummerRoles: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspZrNummerRoleDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspZrNummerRoleDm>
  created: string; // System.DateTime?
  creatorId: string; // string
  creatorEmail: string; // string

  contact_types: any;
  contactTypeIds: Array<Number> | undefined;
}

export interface Value {
  value: GspRole[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspRole>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
    Description: data?.description || '',
    RolesData: data?.rolesData || undefined,
    Contact_types: data?.contact_types || undefined,
    ContactTypeIds: data?.contactTypeIds || undefined,

    Services: data?.services || [],
    UserRoles: data?.userRoles || [],
    ZrNummerRoles: data?.zrNummerRoles || [],
    Created: data?.created || null,
    CreatorId: data?.creatorId || null,
    CreatorEmail: data?.creatorEmail || null,
  };
}

function parse(data?: Partial<GspRole>): GspRole {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    description: data?.description || '',
    rolesData: data?.rolesData || [],
    rolesDataServiceIds: data?.rolesData ? data?.rolesData.map((x: any) => x.serviceId).join(',') : '',
    services: data?.services || '',
    userRoles: data?.userRoles || '',
    zrNummerRoles: data?.zrNummerRoles || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    contact_types: data?.contact_types || '',
    contactTypeIds: data?.contact_types ? data?.contact_types?.map((x:any) => x.id) : undefined,

  };
}

export default {
  parse,
  toAPI: toAPI,
};
