
import { ActionTree } from 'vuex';
import { TblUpdateNewsState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateNews } from '@/shared/backend/tblUpdateNews';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateNews, { BereichListResult, CategoryListResult, AuthorListResult, Value, TblUpdateNews} from '@/shared/model/tblUpdateNews';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateDocument');
export const actions: ActionTree<TblUpdateNewsState, RootState> = {
  getTblUpdateNews({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, newsSearchData?: any  }) {
    console.log('---ACTION  --------------get TblUpdateNews-------------------------------------------  ===>>> payload  ', payload);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateNewsSearchParams;
    let newsSearchData = payload?.newsSearchData || {};
    return defaultBackendTblUpdateNews
      .getTblUpdateNews(searchParams, newsSearchData)
      .then((response: AxiosResponse<Value>) => {
        console.log('---ACTION  --------------get TblUpdateNews-------------------------------------------  ===>>> response.data  ', response.data);
        commit('setTblUpdateNews', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getBereichList({ commit, dispatch } ){
    return defaultBackendTblUpdateNews
    .getBereichList()
    .then((response: AxiosResponse<BereichListResult[]>) => {
      console.log('---ACTION  --------------get get BereichList-------------------------------------------  ===>>> response.data  ', response.data);      
      return response.data;
    })
    .catch((e: any) => {
      module_utils.error('error', commit, e, logger);
      throw e;
    });
  },
  getCategoryList({ commit, dispatch } ){
    return defaultBackendTblUpdateNews
    .getCategoryList()
    .then((response: AxiosResponse<CategoryListResult[]>) => {
      console.log('---ACTION  --------------get get BereichList-------------------------------------------  ===>>> response.data  ', response.data);      
      return response.data;
    })
    .catch((e: any) => {
      module_utils.error('error', commit, e, logger);
      throw e;
    });
  },
  getAuthorList({ commit, dispatch } ){
    return defaultBackendTblUpdateNews
    .getAuthorList()
    .then((response: AxiosResponse<AuthorListResult[]>) => {
      console.log('---ACTION  --------------get AuthorList-------------------------------------------  ===>>> response.data  ', response.data);      
      return response.data;
    })
    .catch((e: any) => {
      module_utils.error('error', commit, e, logger);
      throw e;
    });
  },
  getAddNewsCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateNews
      .getAddNewsCompleted(payload)
      .then(
        (
            response: AxiosResponse<{
              result: TblUpdateNews;
            }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselNewsCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateNews
      .getCanselNewsCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateNews;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },  
  getCountTopNews({ commit, dispatch }, date: number) {
    return defaultBackendTblUpdateNews
      .getCountTopNews()
      .then(response => {        
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },  
};
