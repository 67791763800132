import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateSupplier, { TblUpdateSupplier, CountUpdateRecords, Value } from '../model/tblUpdateSupplier';
import ODataFilterBuilder from 'odata-filter-builder';
import { CompanyWrGruppenResult, LieferantengruppenResult } from '@/shared/model/company';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TblUpdateSupplier');
export interface BackendTblUpdateSupplier {
  getTblUpdateSupplier(id: string): AxiosPromise<TblUpdateSupplier>;
  getTblUpdateSuppliers: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getAddSupplierCompleted(payload: any): AxiosPromise<any>;
  deleteTblUpdateSupplier(id: string): AxiosPromise;
  updateTblUpdateSupplier(TblUpdateSupplier: TblUpdateSupplier): AxiosPromise<any>;
  getCompanyWrGruppen(searchData?: any): AxiosPromise<CompanyWrGruppenResult[]>;
  getLieferantengruppen(searchData?: any): AxiosPromise<LieferantengruppenResult[]>;
  getCanselSupplierCompleted(payload: any): AxiosPromise<any>;
  getCountUpdateRecors: (searchParams: SearchParams, searchData?: any) => AxiosPromise<CountUpdateRecords>;
}

export const defaultBackendTblUpdateSupplier: BackendTblUpdateSupplier = {
  getTblUpdateSupplier(id: string): AxiosPromise<TblUpdateSupplier> {
    let url = `${URLS.tblUpdateSupplierOdata}/${id}`;
    return instance.get<TblUpdateSupplier>(url);
  },
  getTblUpdateSuppliers(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateSupplierOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTblUpdateSuppliers${url}`);
    return instance.put<Value>(url, searchData);
  },
  getCountUpdateRecors(searchParams: SearchParams, searchData?: any): AxiosPromise<CountUpdateRecords> {
    return instance.put(`${URLS.tblUpdateSupplier}/GetCountUpdateRecors`, searchData);
  },
  deleteTblUpdateSupplier(id: string): AxiosPromise {
    logger.debug('deleteTblUpdateSupplier');
    return instance.delete(`${URLS.tblUpdateSupplier}/${id}`);
  },
  updateTblUpdateSupplier(TblUpdateSupplier: TblUpdateSupplier): AxiosPromise<any> {
    logger.debug('updateTblUpdateSupplier');
    return instance.put<TblUpdateSupplier>(
      `${URLS.tblUpdateSupplier}/update`,
      tblUpdateSupplier.toAPI(TblUpdateSupplier)
    );
  },
  getAddSupplierCompleted(data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateSupplier}/AddSupplierCompleted`, data);
  },
  getCanselSupplierCompleted(data: any): AxiosPromise<any> {
    console.log(
      '--BACKEND----------------------------------------------- Get get CanselSupplierCompleted-------------------------------------------------------  ===>>>  data '
    );
    return instance.put(`${URLS.tblUpdateSupplier}/CanselSupplierCompleted`, data);
  },
  getCompanyWrGruppen(searchData?: any): AxiosPromise<CompanyWrGruppenResult[]> {
    console.log(
      '--BACKEND----------------------------------------------- Get CompanyWrGruppen-------------------------------------------------------  ===>>>  data '
    );
    //return instance.get<CompanyZrResult[]>(`${URLS.contact}/GetCompanyZr/${isAllCompanies}/${contactType}`);
    return instance.put<CompanyWrGruppenResult[]>(`${URLS.tblUpdateSupplier}/GetCompanyWrGruppen`, searchData);
    //return instance.get<CompanyZrResult[]>(url);
  },
  getLieferantengruppen(searchData?: any): AxiosPromise<LieferantengruppenResult[]> {
    console.log(
      '--BACKEND----------------------------------------------- Get Lieferantengruppen-------------------------------------------------------  ===>>>  data '
    );
    //return instance.get<CompanyZrResult[]>(`${URLS.contact}/GetCompanyZr/${isAllCompanies}/${contactType}`);
    return instance.put<LieferantengruppenResult[]>(`${URLS.tblUpdateSupplier}/GetLieferantengruppen`, searchData);
    //return instance.get<CompanyZrResult[]>(url);
  },
};
