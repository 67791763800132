var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded)?_c('div',[(!_vm.isSingleRow && !_vm.noAccess)?_c('edit-multiple-module-roles-dialog',{attrs:{"dialog":_vm.editMultipleModuleRolesDialog.dialog,"editedUserRoleDetails":_vm.editMultipleModuleRolesDialog.editedUserRoleDetails,"roles":_vm.roles},on:{"click:save":_vm.onEditMultipleModuleRolesDialogSave,"click:close":_vm.onEditMultipleModuleRolesDialogClose}}):_vm._e(),(!_vm.isSingleRow && !_vm.noAccess)?_c('div',{staticClass:"d-flex justify-end my-1 mr-5"},[_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.onEditMultipleModuleRoles}},[_vm._v(_vm._s(_vm.$t('edit_all')))])],1):_vm._e(),(_vm.moduleZrNumArr[0] && _vm.moduleZrNumArr[0].moduleRoles)?_c('table',[_c('thead',[_c('tr',[_c('th',[(!_vm.isSingleRow)?_c('span',[_vm._v(_vm._s(_vm.$t('zr_number')))]):_vm._e()]),_vm._l((_vm.moduleZrNumArr[0].moduleRoles),function(item,i){return _c('th',[(item.isCommon && _vm.serviceModuleCommonText)?_c('span',[_c('v-tooltip',{attrs:{"color":item.isCommon && _vm.serviceModuleCommonText ? '#909090' : 'transparent',"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.serviceModuleCommonText)+" ")])]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"width":"400px"}},[(item && item.name)?_c('div',{staticStyle:{"color":"white","text-align":"left","width":"350px"}},[_vm._v(_vm._s(item.name))]):_vm._e()])])],1):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])})],2)]),_c('tbody',_vm._l((_vm.moduleZrNumArr),function(item1,i){return _c('tr',[_c('td',[(!_vm.isSingleRow)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isSingleRow)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3 mb-1 mr-2",attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getNameCompany(item1.zrNummer, item1.zrNummerAll)))])]):_vm._e(),(!_vm.isSingleRow)?_c('span',[_vm._v(_vm._s(item1.zrNummer || item1.zrNummerAll))]):_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('roles')))])])],1),_vm._l((item1.moduleRoles),function(item2,j){return _c('td',{staticClass:"py-1"},[_c('d4y-autocomplete',{staticClass:"ml-10 mr-4",attrs:{"label":_vm.$t('roles'),"multiple":"","hide-details":"","name":"roles","items":_vm.roles,"item-text":function (item) { return item ? item.name : ''; },"item-value":function (item) { return item.id; },"clearable":"","disabled":_vm.noAccess},on:{"change":function($event){return _vm.changeModuleRole($event, item2, item1.zrNummer)},"input":_vm.changeRoleValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('v-list-item-action',{staticClass:"mr-2"},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"input-value":item2.roles.includes(item.id)}}),_c('v-tooltip',{attrs:{"color":item.description ? '#909090' : 'transparent',"content-class":"custom-supplier-tooltip","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.description)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":!(item.description),"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"width":"400px"}},[(item.description)?_c('div',{staticStyle:{"color":"white","text-align":"left","width":"350px"}},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()])])],1)]),_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.name))}})])],1)],1)]}}],null,true),model:{value:(item2.roles),callback:function ($$v) {_vm.$set(item2, "roles", $$v)},expression:"item2.roles"}})],1)})],2)}),0)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }