import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateNews, { BereichListResult, CategoryListResult, AuthorListResult, TblUpdateNews, Value } from '../model/tblUpdateNews';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TblUpdateSupplier');
export interface BackendTblUpdateNews {  
  getTblUpdateNews: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;  
  getAddNewsCompleted(payload: any): AxiosPromise<any>;  
  getCanselNewsCompleted(payload: any): AxiosPromise<any>;
  getBereichList():AxiosPromise<BereichListResult[]>;
  getCategoryList():AxiosPromise<CategoryListResult[]>;
  getAuthorList():AxiosPromise<AuthorListResult[]>;
  getCountTopNews():AxiosPromise<number>;
}

export const defaultBackendTblUpdateNews: BackendTblUpdateNews = {
  getTblUpdateNews(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateNewsOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
   ;
    if (searchParams.filter?.length != 0)
    {
        url=url.replace("contains(tolower(nw_createdate),","contains(tolower(cast(nw_createdate, 'Edm.String')),");
        url=url.replace("contains(tolower(newsDoneTimeCompleted),","contains(tolower(cast(newsDoneTimeCompleted, 'Edm.String')),");
    }
    console.log('-BACKEND -------------------get TblUpdateNews----------------------------------------  ===>>>searchData   ', searchData  , ' url  ', url);
    return instance.put<Value>(url, searchData);
  },
  getBereichList():AxiosPromise<BereichListResult[]> {
    console.log('-BACKEND -------------------get get BereichList----------------------------------------  ===>>>  ');
    return instance.put(`${URLS.tblUpdateNews}/GetBereichList`);
  },
  getCategoryList():AxiosPromise<CategoryListResult[]> {
    console.log('-BACKEND -------------------get Get CategoryList----------------------------------------  ===>>>  ');
    return instance.put(`${URLS.tblUpdateNews}/GetCategoryList`);
  },
  getAuthorList():AxiosPromise<AuthorListResult[]> {
    console.log('-BACKEND -------------------get Get AuthorList---------------------------------------  ===>>>  ');
    return instance.put(`${URLS.tblUpdateNews}/GetAuthorList`);
  }, 
  getAddNewsCompleted( data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateNews}/AddNewsCompleted`, data);
  },
  getCanselNewsCompleted( data: any): AxiosPromise<any> {
    console.log('-BACKEND -------------------get get CanselNewsCompleted---------------------------------------  ===>>>  ');
    return instance.put(`${URLS.tblUpdateNews}/CanselNewsCompleted`, data);
  },
  getCountTopNews(  ): AxiosPromise<number> {
    console.log('-BACKEND ------------------get CountTopNews---------------------------------------  ===>>>  ');
    return instance.put(`${URLS.tblUpdateNews}/GetCountTopNews`);
  },
};
