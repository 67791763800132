
import { MutationTree } from 'vuex';
import { GspServiceState } from './types';
import Vue from 'vue';
import gspService, { GspService, Value } from '@/shared/model/gspService';

export const mutations: MutationTree<GspServiceState> = {
  setGspServices(state, payload: Value) {
    state.gspServices.items = payload.value.map((x) => gspService.parse(x));
    state.gspServices.total = payload['@odata.count'] || 0;
    state.gspServices.isLoading = false;
  },
  setGspServicesTotal(state, payload: number) {
    state.gspServices.total = payload;
  },
  setGspServicesIsLoading(state, payload: boolean) {
    state.gspServices.isLoading = payload;
  },

  setGspServicesForContacts(state, payload: GspService[]) {
    state.gspServicesForContacts = payload;
  },
  setGspServicesForContactsIsLoading(state, payload: boolean) {
    state.gspServicesForContactsIsLoading = payload;
  },


  setServiceCommonText(state, payload: any) {
    const service = state.gspServices.items.find((x) => x.id == payload.id);
    if (service) {
      service.short_common_text = payload.short_common_text;
    }
  },
};
