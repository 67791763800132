/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gsp, {
  GspUserRoleDetail,
  GspUserRoleDetailInner,
  GspUserRoleDetailModuleRole,
} from '@/shared/model/gspUserRoleDetail';
import { RoleOperation } from '@/shared/utils/Constants';
import { GspUserRole } from '@/shared/model/gspUserRole';
import { deepClone } from '@/shared/utils/generalUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { GspRole } from '@/shared/model/gspRole';
import { VBtn } from 'vuetify/lib';
import EditMultipleModuleRolesDialog from './edit-multiple-module-roles/edit-multiple-module-roles.vue';
import { UserActionType } from '@/shared/model/userActionsUserRoleChanges';

const logger = new Logger('module-role-table');
const contactModule = namespace('contact');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleDetailModule = namespace('gspUserRoleDetail');

@Component({
  components: { VBtn, EditMultipleModuleRolesDialog },
})
export default class ModuleRoleTable extends Vue {
  @gspServiceModule.Action('getGspServices')
  private actionGetGspServices!: any;
  @gspUserRoleModule.Action('addRoleFromBackend')
  private actionAddRoleFromBackend!: any;
  @gspUserRoleModule.Action('addRoleDetailFromBackend')
  private actionAddRoleDetailFromBackend!: any;
  @gspUserRoleDetailModule.Action('getGspUserRoleDetail')
  private actionGetGspUserRoleDetail!: any;
  @gspUserRoleDetailModule.Action('updateGspUserRoleDetail')
  private actionUpdateGspUserRoleDetail!: any;
  @gspUserRoleDetailModule.Action('addUserFromManageMaxService')
  private actionAddUserFromManageMaxService!: any;
  @gspUserRoleDetailModule.Action('deleteUserFromManageMaxService')
  private actionDeleteUserFromManageMaxService!: any;
  @gspUserRoleDetailModule.Action('updateGspUserRoleDetailMultipleRoles')
  private actionUpdateGspUserRoleDetailMultipleRoles!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: any;

  @contactModule.Action('getMasterZrGsp')
  private actionGetMasterZrGsp!: any;

  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: OdataItems<GspRole>;

  @contactModule.Getter('getCompanyZrs')
  private companyZrNums!: any;

  @Prop()
  private serviceModules!: any;
  @Prop()
  private zrNumberArr!: any;
  @Prop({ default: false })
  private noAccess!: boolean;

  @Prop()
  private userRole!: GspUserRole;
  @Prop()
  private service!: any;
  @Prop()
  private serviceModuleCommonText!: any;
  @Prop()
  private contactId!: any;
  @Prop()
  private contactData!: any;
  @Prop()
  private contactEmail!: any;
  @Prop()
  private isSingleRow!: boolean;

  moduleZrNumArr: GspUserRoleDetailInner[] = [];
  moduleZrNumArrBackup: GspUserRoleDetailInner[] = [];

  editMultipleModuleRolesDialog: {
    dialog: boolean;
    editedUserRoleDetails: GspUserRoleDetailInner[];
  } = {
    dialog: false,
    editedUserRoleDetails: [],
  };

  // private async getGspServices() {
  //   await this.actionGetGspServices()
  //     .then((result: any) => {
  //       this.servicesArr = result.value;
  //     })
  //     .catch((err: any) => {
  //       logger.error(err);
  //     });
  // }
  servicesArr: any = [];
  // modulesArr: any = [];
  // attributesArr: any = [];

  get isHaveCommonModules() {
    return this.serviceModules?.filter((x: any) => x.isCommon).length > 1;
  }

  get roleServices() {
    return this.roles?.map((x: any) => x.id);
    // return this.userRole?.gspUserRoleDetails.map((x:any) => x.serviceId);
  }

  get isGspService() {
    // return this.serviceId == 5; // (AD-103) bad approach since `serviceId` position can change (encounter in Prod) and Service can be easily renamed, so for now just use `GetMasterZrGsp` logic for Services whrere multiple ZrNummer used (only GSP for now (on 2024-06))
    // (AD-103) decided to use separate backend field in GspService table
    return this.service.is_call_zr_master;
  }
  get zrNumberArrWithMaster() {
    if (this.isGspService) {
      let res = [...new Set(this.zrNumberArr.concat(this.zrMasterGsp))];
      return res;
    }
    return this.zrNumberArr;
  }
  // get services() {
  //   return this.servicesArr?.items;
  // }

  /**
   *
   * var lat = [
  [45.4668729, 44.8013268, 45.4384958]
];
var lng = [
  [9.1907501, 10.3278351, 10.9924122]
];



var finalArray = []

lat[0].forEach((i, index)=>{
  finalArray.push([i,lng[0][index]])
})
   */
  currentRoleDetail?: GspUserRoleDetail = gsp.parse({});
  currentRoleDetailInit?: GspUserRoleDetail = gsp.parse({}); // to compare initial and changed role to log added/deleted role in database

  async created() {
    var promiseAll = [this.getMasterZrForGsp()];
    await Promise.all(promiseAll);
  }

  zrMasterGsp: any = [];
  private dataLoaded = false;
  initComponent() {
    this.logicFromMounted();
    this.dataLoaded = true;
  }
  async getMasterZrForGsp() {
    if (!this.isGspService) {
      this.initComponent();
      return;
    }
    // console.log(this.contact.companyZrNumber)
    let zrArray = this.zrNumberArr;
    // zrArray = zrArray.map(x=> x.trim());
    await this.actionGetMasterZrGsp(zrArray)
      .then((result: any) => {
        console.log('actionGetMasterZrGsp result :>> ', result.result);
        let resArr = result.result;
        //let difference = arr1.filter(x => !arr2.includes(x));
        let diff = resArr.filter((x: any) => !zrArray.includes(x));
        console.log('diff :>> ', diff);
        this.zrMasterGsp = diff;
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => this.initComponent());
  }

  @Watch('userRole')
  onUserRoleUpdated() {
    logger.debug('----userRoleUpdated----');
    this.getMasterZrForGsp();
  }

  async mounted() {
    // var promiseAll = [this.getGspServices()];
    // await Promise.all(promiseAll);
    // // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // // console.log('mounted userRole result :>> ', result);
    // // console.log('mounted  contact:>> ', this.contact.email);
    // if (this.userRole?.zrNumRolesData) {
    //   this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);
    //   this.userRoleData.forEach((element: any, index) => {
    //     if (element?.zrNummer) {
    //       Vue.set(this.boxes, index, true);
    //       Vue.set(this.roleBoxes, index, element.roles);
    //     }
    //   });
    //   this.userRolesPayload = this.userRoleData;
    // }
  }

  logicFromMounted() {
    this.currentRoleDetail = this.userRole?.gspUserRoleDetails.find((x: any) => x.serviceId === this.service.id);
      this.currentRoleDetailInit = this.currentRoleDetail != undefined ? deepClone(this.currentRoleDetail) : undefined;

    // this.serviceModules.forEach((el:any, index: number) => {
    //   this.moduleZrNumArr.push({'zrNummer': this.zrNumberArr[index],'module': el})
    // })
    console.log('this.userRole :>> ', this.userRole);
    // console.log('this.serviceModules OA:>> ', Object.assign({}, this.serviceModules));
    let modules = this.serviceModules;
    // console.log('this.serviceModules :>> ', this.serviceModules);

    if (this.isHaveCommonModules) {
      // if (this.serviceModules.length > 1 && this.serviceModules[0].name.includes(this.serviceModules[1].name)) {
      //   console.log('already all common modules concatenated :>> ');
      //   // (AD-95) fix bug that `common` long modules string is duplicated for each new Contact (without created role)
      //   modules = [this.serviceModules[0]];
      //   console.log('modules ex :>> ', modules);
      // } else {
      const result = this.serviceModules.reduce((acc: any, cur: any) => {
        // console.log('acc :>> ', Object.assign({},acc));
        // console.log('cur :>> ', Object.assign({},cur));
        let doesExist = acc.find((a: any) => a.isCommon);
        // console.log('doesExist :>> ', Object.assign({},doesExist));

        if (doesExist && cur.isCommon) {
          if (!doesExist.name.includes(cur.name)) {
            doesExist.name = `${doesExist.name},${cur.name}`;
            doesExist.isCommon = true;
          }
        } else {
          acc.push(cur);
        }
        return acc;
      }, []);
      modules = result;
      // }
    }

    const roleDetails =
      this.currentRoleDetail && this.currentRoleDetail.roleDetails && this.currentRoleDetail.roleDetails.length > 0;

    this.moduleZrNumArr = [];

    if (this.userRole?.id && this.userRole.gspUserRoleDetails.length > 0 && roleDetails) {
      //console.log('this.currentRoleDetail!.roleDetails :>> ', this.currentRoleDetail!.roleDetails);
      // !! TODO(AD-89) decide how to read existing data if Modules `IsCommon` checkbox was changed
      this.moduleZrNumArr = this.currentRoleDetail!.roleDetails as GspUserRoleDetailInner[];
      //console.log('parse :>> ', JSON.stringify(this.moduleZrNumArr));

      //console.log('this.moduleZrNumArr LEN :>> ', this.moduleZrNumArr.length);
      //console.log('this.this.zrNumberArrWithMaster.length :>> ', this.zrNumberArrWithMaster.length);
      if (this.zrNumberArrWithMaster.length > this.moduleZrNumArr.length && this.isGspService) {
        let currentZrs = this.moduleZrNumArr.map((x: any) => x.zrNummer);
        //console.log('currentZrs :>> ', currentZrs);
        let diff = this.zrNumberArrWithMaster.filter((x: any) => !currentZrs.includes(x));
        //console.log('diff :>> ', diff);
        this.addRolesToExisting(diff);
      }
      if (this.moduleZrNumArr.length) {
        // TODO: specify from Chaslau what to do if changes in Module isCommon status
      }
    } else {
      console.log('init :>> ');

      this.initNewData(modules);
    }
  }

  addRolesToExisting(zrs: any) {
    zrs.forEach((el: any, index: number) => {
      const moduleRoles = this.serviceModules.map((x: any) => {
        return { id: x.id, name: x.name, roles: [] };
      });

      this.moduleZrNumArr.push({ zrNummer: el.toString().trim(), moduleRoles: moduleRoles }); // was zrNummerAll which is wrong for multiple zrNummer
    });
  }

  initNewData(modules: any) {
    console.log('modules :>> ', modules);
    console.log('this.isSingleRow :>> ', this.isSingleRow);
    if (this.isSingleRow) {
      const moduleRoles = modules.map((x: any) => {
        return { id: x.id, name: x.name, isCommon: x.isCommon, roles: x.roles ?? [] };
      });
      const zrNummerAll = this.zrNumberArr.map((x: any) => x.trim()).join(',');

      this.moduleZrNumArr.push({ zrNummerAll: zrNummerAll, moduleRoles: moduleRoles });
    } else {
      this.zrNumberArrWithMaster.forEach((el: any, index: number) => {
        const moduleRoles = modules.map((x: any) => {
          return { id: x.id, name: x.name, roles: x.roles ?? [] };
        });

        this.moduleZrNumArr.push({ zrNummer: el.toString().trim(), moduleRoles: moduleRoles }); // was zrNummerAll which is wrong for multiple zrNummer
      });
    }
  }

  async changeModuleRole(newRoles: number[], item: GspUserRoleDetailModuleRole, /*modPosIndex: number,*/ zr: string) {
    // console.log('this.moduleZrNumArr OA currentRoleDetail:>> ', Object.assign({},this.currentRoleDetail));
    // console.log('this.moduleZrNumArr OAcurrentRoleDetailInit :>> ', Object.assign({},this.currentRoleDetailInit));
    // console.log('this.moduleZrNumArr OA :>> ', Object.assign({},this.moduleZrNumArr));
    // console.log('newVal :>> ', newRoles);
    // console.log('item :>> ', item);
    // console.log('i zr :>> ', modPosIndex);
    // console.log('zrzr zr :>> ', zr);
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--------------change ---  ModuleRole----------------------------------------------  ===>>> this.currentRoleDetailInit  ', this.currentRoleDetailInit, '   this.userRole  ', this.userRole);
    const previousItemState = this.currentRoleDetailInit?.roleDetails as GspUserRoleDetailInner[];
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--------------change ---  ModuleRole----------------------------------------------  ===>>> this.currentRoleDetailInit  ', this.currentRoleDetailInit, '  item ', item);
    // console.log('previousItemState :>> ', previousItemState);
    let prev: GspUserRoleDetailModuleRole | null | undefined = null;

    if (previousItemState) {
      for (let roleDetail of previousItemState) {
        if (roleDetail.zrNummer != zr) continue;

        let found = roleDetail.moduleRoles?.find((x) => x.id == item.id);
        if (found != undefined) {
          prev = found;
          break;
        }
      }
    }

    // console.log('prev.roles :>>', JSON.stringify(prev?.roles));

    // else if (!!zr) {
    //   prev = previousItemState.find((x: any) => x.zrNummer == zr)
    // }
    // console.log('ev.length :>> ', ev.length);
    // console.log('prev :>> ', prev);
    // console.log('prev.roles?.length :>> ', prev?.roles?.length);
    let operation = '';
    let difference: number[] = [];
    if (!prev?.roles || newRoles.length > prev.roles?.length) {
      operation = RoleOperation.add;
      difference = newRoles.filter((x: any) => !prev?.roles?.includes(x));
    } else if (!!prev?.roles) {
      // console.log('prev.roles?.lengths - ev.length > 1 :>> ', prev.roles?.length - ev.length);
      operation = prev.roles?.length - newRoles.length > 1 ? RoleOperation.deleteAll : RoleOperation.delete;
      difference = prev.roles.filter((x) => !newRoles.includes(x));
    }

    // console.log('operation :>> ', operation);
    // console.log('difference :>> ', difference);

    // let zrss = this.moduleZrNumArr.find((x:any)=> x.zrNummer == zr)g
    // console.log('zrss :>> ', zrss);
    // zrss.moduleRoles[modPosIndex].roles= ev;
    //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--------------change ---  ModuleRole------------------------------------------------------------------  ===>>>this.currentRoleDetail   ', this.currentRoleDetail);
    for (let roleId of difference) {
      let roleDetailsModel = gsp.parse({});

      if (this.currentRoleDetail?.id) {
        console.log('this.currentRoleDetail?.id :>> ', this.currentRoleDetail?.id);
        roleDetailsModel = this.currentRoleDetail;
      }
      // if (!this.userRole?.id) {
      roleDetailsModel.contactId = this.contactId;
      roleDetailsModel.contactEmail = this.contactEmail;
      // }
      roleDetailsModel.actionType = operation;
      roleDetailsModel.roleId = roleId;

      if (operation == RoleOperation.deleteAll) {
        let roleNames = this.roles.filter((x: any) => prev?.roles?.includes(x.id)).map((x: any) => x.name);
        roleDetailsModel.roleName = `(${roleNames.length} roles) ${roleNames.join(',')}`;
      } else {
        // The typescript compiler seems to be buggy here, so I needed to mark these variables as any.
        let role = this.roles.find((x: any) => x.id == roleId);
        roleDetailsModel.roleName = (role as any).name ?? '';
      }

      roleDetailsModel.moduleId = item.id!;
      roleDetailsModel.isCommonModule = item.isCommon!;

      // console.log('aaaa object this.moduleZrNumArr :>> ', Object.assign({},this.moduleZrNumArr));

      // console.log('rolesCount :>> ', rolesCount);
      roleDetailsModel.roleDetails = this.moduleZrNumArr;
      roleDetailsModel.gspUserRoleId = (this.userRole?.id || this.currentRoleDetail?.gspUserRoleId)!;
      roleDetailsModel.serviceId = this.service.id;
      roleDetailsModel.zrNummer = zr;

      console.log('roleDetailsModel :>> ', roleDetailsModel);
      console.log('roleDetailsModel  this.service:>> ', this.service);
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> roleDetailsModel  ', roleDetailsModel);
      await this.actionUpdateGspUserRoleDetail(roleDetailsModel)
        .then((result: any) => {
          console.log('change ModuleRole actionUpdateGspUserRoleDetail result :>> ', result);
          this.currentRoleDetail = gsp.parse(result.result);
          this.currentRoleDetail.gspUserRole = null; // avoid  error when select multiple Roles for nonexisting `UserRole` (or need `parse` `UserRole` model)
          
          this.currentRoleDetailInit = Object.assign({}, gsp.parse(result.result));
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  ', this.currentRoleDetailInit);
          if (this.service.name?.toLowerCase().includes('managemax')) {
            console.log('roleDetailsModel managemax :>> ');
            if (roleDetailsModel.actionType==RoleOperation.add) {
              const maxPayload = {
                contactEmail: this.contactEmail,
                firstName: this.contactData.firstName,
                lastName: this.contactData.lastName,
                UserGroupId: 1, // discussed with Chaslau (for now always 1)
                zrNummernCsv: this.zrNumberArr?.join(',') + '',
                azureUserId: this.contactData.contactAzureId,

                azureGroupId: this.service.entra_Group
              }
              this.actionAddUserFromManageMaxService(maxPayload);
            } else {
              this.actionDeleteUserFromManageMaxService({contactEmail: this.contactEmail, azureUserId: this.contactData.contactAzureId, azureGroupId: this.service.entra_Group});
            }

          }
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  3');
          if (!this.userRole?.id) {
            console.log('result.result.gspUserRole :>> ', result.result.gspUserRole);
            let payload = result.result.gspUserRole;
            payload.gspUserRoleDetails = [this.currentRoleDetail];
            this.actionAddRoleFromBackend(payload);
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  4');

          } else {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  5');
            this.actionAddRoleDetailFromBackend(result.result);
          }
        })
        .catch((err: any) => {
          logger.error(err);
        });

        this.$emit('click:saveChangeModuleRole', this.contactId);
    }
    
  }

  changeRoleValue(){
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!-------------------change RoleValue--------------change RoleValue-------------------------------  ===>>> this.currentRoleDetailInit  7777777 this.contactId  ', this.contactId );
    //this.$emit('click:saveChangeModuleRole', this.contactId);
  }

  // changeRole(ev: any, item: any, i: any) {
  //   if (ev.length == 0) {
  //     this.removeZrNumRole('role', i);
  //   }
  //   console.log('ev :>> ', ev);
  //   console.log('item :>> ', item);
  //   console.log('i zr :>> ', i);

  //   let obj = { zrNummer: item.trim(), roles: ev };
  //   this.userRolesPayload[i] = obj;
  //   // this.boxes[i] = ev.length>0 ;
  //   Vue.set(this.boxes, i, ev.length > 0);

  //   // Array.prototype.splice
  //   // vm.items.splice(indexOfItem, 1, newValue)
  // }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  // get userRole() {
  //   // const result = this.userRoles.items.find((role: any) => role.contactEmail == this.contact.email);
  //   const result: any = '';
  //   return result;
  // }

  get roles(): GspRole[] {
    var serviceRoles = this.gspRoles.items.filter((r: any) => r.rolesDataServiceIds.includes(this.service.id));
    return serviceRoles;
    return this.gspRoles.items;
  }

  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    // const payload = {
    //   id: this.userRole?.id || undefined,
    //   contactId: this.contact.recordID,
    //   contactEmail: this.contact.email,
    //   zrNumRolesData: JSON.stringify(this.userRolesPayload),
    // };
    // console.log('payload :>> ', payload);

    // this.actionUpdateGspUserRole(payload);
  }
  //#endregion

  private onEditMultipleModuleRoles() {
    //const oldRoleDetails = this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[];
    //console.log('##############################################-------------------on on EditMultipleModuleRoles-----------------------------------------  ===>>> oldRoleDetails  ', oldRoleDetails);
    this.editMultipleModuleRolesDialog.editedUserRoleDetails = deepClone(this.moduleZrNumArr);
    this.editMultipleModuleRolesDialog.dialog = true;
  }

  private onEditMultipleModuleRolesDialogClose() {
    this.editMultipleModuleRolesDialog.dialog = false;
  }

  private async onEditMultipleModuleRolesDialogSave(editedInnerUserRoleDetails: GspUserRoleDetailInner[]) {
    this.editMultipleModuleRolesDialog.dialog = false;
    console.log('##############################################-------------------on on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> editedInnerUserRoleDetails  ', editedInnerUserRoleDetails);
    const newRoleDetails = this.moduleZrNumArr;
    for (let innerUserRoleDetail of editedInnerUserRoleDetails) {
      if (innerUserRoleDetail.moduleRoles == undefined) continue;

      for (let moduleRole of innerUserRoleDetail.moduleRoles) {
        const moduleRoleInNewRoleDetails = newRoleDetails
          .find((x) => x.zrNummer == innerUserRoleDetail.zrNummer)
          ?.moduleRoles?.find((x) => x.name == moduleRole.name);
        if (moduleRoleInNewRoleDetails != undefined) moduleRoleInNewRoleDetails.roles = moduleRole.roles;
      }
    }
    console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> this.currentRoleDetail 1 ', this.currentRoleDetail, ' this.service  ', this.service);
    const userRoleDetailToUpdate = <GspUserRoleDetail>{ ...this.currentRoleDetail };
    userRoleDetailToUpdate.roleDetails = newRoleDetails;
    userRoleDetailToUpdate.contactId = this.contactId;
    userRoleDetailToUpdate.serviceId = this.service.id;
    console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> oldRoleDetail 2 ', userRoleDetailToUpdate);
    // Structure needed to add a log entry for each changed module role.
    let moduleRoleChanges: {
      ContactId: number;
      ServiceId: number;
      ContactEmail: string;
      ActionType: UserActionType;
      RoleId?: number;
      ModuleId?: number;
      IsCommonModule?: boolean;
      RoleName: string;
      ZrNummer?: string;
    }[] = [];
    //this.currentRoleDetailInit = deepClone(userRoleDetailToUpdate);
    console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> this.current RoleDetailInit 3 ', this.currentRoleDetailInit);
    // Figure out what has changed and create respective log entries
    //let oldRoleDetails: GspUserRoleDetailInner[];
   
   
    //const oldRoleDetails  = Object.assign({}, this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[]);
    // if (this.currentRoleDetailInit != undefined)
    // {
    //   oldRoleDetails = this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[];
    // }
    // else
    // {
    //   oldRoleDetails=editedInnerUserRoleDetails;
    // }
    //console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> oldRoleDetails 4 ', oldRoleDetails);
    if (this.currentRoleDetailInit != undefined)
    {
      const oldRoleDetails = this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[];
      for (let oldRoleDetail of oldRoleDetails) {
        let newRoleDetail = newRoleDetails.find((x) => x.zrNummer == oldRoleDetail.zrNummer);
        console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> oldRoleDetail 5 ', oldRoleDetail, '  newRoleDetail  ', newRoleDetail);
        for (let oldModuleRole of oldRoleDetail.moduleRoles ?? []) {
          console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> oldModuleRole 5_000 ', oldModuleRole);
          let newModuleRole = newRoleDetail?.moduleRoles?.find((x) => x.id == oldModuleRole.id);
          console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> newModuleRole 5_001 ', newModuleRole);
          let addedModuleRoles = newModuleRole?.roles?.filter((x) => !oldModuleRole.roles?.includes(x));
          console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> addedModuleRoles 5_002 ', addedModuleRoles);
          let deletedModuleRoles = oldModuleRole.roles?.filter((x) => !newModuleRole?.roles?.includes(x));
          console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> deletedModuleRoles 5_003 ', deletedModuleRoles);
          console.log('-------------------on EditMultipleModuleRolesDialogSave-----------------------------------------  ===>>> oldModuleRole 6 ', oldModuleRole, '  this.contactEmail ', this.contactEmail);
          for (let addedModuleRole of addedModuleRoles ?? []) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Add',
              RoleId: addedModuleRole,
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == addedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
          for (let deletedModuleRole of deletedModuleRoles ?? []) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Delete',
              RoleId: deletedModuleRole,
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == deletedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
        }
      }
    }
    else
    {
      
      for (let newRoleDetail of editedInnerUserRoleDetails) {
       
        for (let newdModuleRole of newRoleDetail.moduleRoles ?? []) {
          let newModuleRole = newRoleDetail?.moduleRoles?.find((x) => x.id == newdModuleRole.id);
          let addedModuleRoles = newModuleRole?.roles;
          for (let addedModuleRole of addedModuleRoles ??[]) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Add',
              RoleId: addedModuleRole,              
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == addedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
        }

      }
    }

    

    this.currentRoleDetailInit = deepClone(userRoleDetailToUpdate);

    const payload = {
      userRoleDetail: userRoleDetailToUpdate,
      moduleRoleChanges: moduleRoleChanges,
    };
    console.log('--------------------------------on EditMultipleModuleRolesDialogSave------------------------------------------------  ===>>> payload  ',payload);
    await this.actionUpdateGspUserRoleDetailMultipleRoles(payload)
    .then((result: any) => {
      if (!this.userRole?.id) {
        console.log('result.result.gspUserRole :>> ', result.result.gspUserRole);
        let payload = result.result.gspUserRole;
        payload.gspUserRoleDetails = [this.currentRoleDetail];
        this.actionAddRoleFromBackend(payload);
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  4');

      } else {
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!-----------------------action----UpdateGspUserRoleDetail-------------------------------------  ===>>> this.currentRoleDetailInit  5');
        this.actionAddRoleDetailFromBackend(result.result);
      }
    })
    .catch((err: any) => {
      logger.error(err);
    });;
  }

  getNameCompany(zrnumer: string, zrnumerAll: string) {
    let valueZRNummer: string = '';

    if (zrnumer != undefined && zrnumer.toString().length != 0) {
      valueZRNummer = zrnumer.toString().trim();
    } else if (zrnumerAll != undefined && zrnumerAll.toString().length != 0) {
      valueZRNummer = zrnumerAll.toString().trim();
    }
    let companyName = this.companyZrNums.items.find((x: any) => x.zrNummer == valueZRNummer)?.companyName;
    return companyName;
  }
}
