
import { MutationTree } from 'vuex';
import { GspRoleState } from './types';
import Vue from 'vue';
import gspRole, { GspRole, Value } from '@/shared/model/gspRole';

export const mutations: MutationTree<GspRoleState> = {
  setGspRoles(state, payload: Value) {
    state.gspRoles.items = payload.value.map((x) => gspRole.parse(x));
    state.gspRoles.total = payload['@odata.count'] || 0;
    state.gspRoles.isLoading = false;
  },
  setGspRolesTotal(state, payload: number) {
    state.gspRoles.total = payload;
  },
  setGspRolesIsLoading(state, payload: boolean) {
    state.gspRoles.isLoading = payload;
  },
  setGspRoleContactTypes(state, payload: any) {
    state.gspRoleContactTypes = payload;
  },

  setGspRole(state, payload: any) {
    state.gspRole = payload;
  },
};
