
import { MutationTree } from 'vuex';
import { TblUpdateSupplierState } from './types';
import Vue from 'vue';
import tblUpdateSupplier, { TblUpdateSupplier, Value } from '@/shared/model/tblUpdateSupplier';

export const mutations: MutationTree<TblUpdateSupplierState> = {
  setTblUpdateSuppliers(state, payload: Value) {
    state.tblUpdateSuppliers.items = payload.value.map((x) => tblUpdateSupplier.parse(x));
    state.tblUpdateSuppliers.total = payload['@odata.count'] || 0;
    state.tblUpdateSuppliers.isLoading = false;
  },
  setTblUpdateSuppliersTotal(state, payload: number) {
    state.tblUpdateSuppliers.total = payload;
  },
  setTblUpdateSuppliersIsLoading(state, payload: boolean) {
    state.tblUpdateSuppliers.isLoading = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setCompanyWrGruppen(state, payload: any) {
    state.companyWrGruppen.items = payload;
    state.companyWrGruppen.total = payload.length;
    state.companyWrGruppen.isLoading = false;
  },
  setLieferantengruppen(state, payload: any) {
    state.lieferantengruppen = payload;
  },
  setCompanyWrGruppenIsLoading(state, payload: boolean) {
    state.companyWrGruppen.isLoading = payload;
  },
  setDashboarCompanyWrGruppen(state, payload: any) {
    state.dashboarCompanyWrGruppen = payload;
  },
  setDashboardSuppliersStatus(state, payload: any) {
    state.dashboardSuppliersStatus = payload;
  },
  setDashboardLieferantengruppen(state, payload: any) {
    state.dashboardLieferantengruppen = payload;
  },
};
