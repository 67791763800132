export type UserActionType = 'Add' | 'Delete' | 'DeleteAll' | 'AddStatus' | 'RemoveStatus';

export interface UserActionsUserRoleChanges {
  id: number;
  contact_id: string; // ulong
  gsp_user_role_id: number;
  user_action_type: UserActionType;
  service_id: number;
  module_id: number;
  is_common_module?: boolean;
  role_id: number;
  role_name: string;
  erstellt_von_email?: string;
  erstellt_von_id?: string; // Guid
  erstellt_am?: string; // DateTime
  service_name: string;
  module_name: string;
  zr_nummer: string;
}

export interface Value {
  value: UserActionsUserRoleChanges[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: UserActionsUserRoleChanges): UserActionsUserRoleChanges {
  return {
    id: data?.id || 0,
    contact_id: data?.contact_id || '',
    gsp_user_role_id: data?.gsp_user_role_id || 0,
    user_action_type: data?.user_action_type || 'Add',
    service_id: data?.service_id || 0,
    module_id: data?.module_id || 0,
    is_common_module: data?.is_common_module ?? undefined,
    role_id: data?.role_id || 0,
    role_name: data?.role_name || '',
    erstellt_von_email: data?.erstellt_von_email || undefined,
    erstellt_von_id: data?.erstellt_von_id || undefined,
    erstellt_am: data?.erstellt_am || undefined,
    service_name: data?.service_name || '-',
    module_name: data?.module_name || '',
    zr_nummer: data?.zr_nummer || '',
  };
}

export default {
  parse,
};
