
import { GetterTree } from 'vuex';
import { GspRoleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspRoleState, RootState> = {
  ['getGspRoles']: (state) => state.gspRoles,
  ['getGspRoleContactTypes']: (state) => state.gspRoleContactTypes,

  ['getGspRole']: (state) => state.gspRole,
  ['getGspRolesIsLoading']: (state) => state.gspRoles?.isLoading,
  ['getGspRolesTotal']: (state) => state.gspRoles?.total,
  ['getGspRolesSearchParams']: (state) => state.gspRoles?.searchParams,
};
