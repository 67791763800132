import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspRole, { ContactType, GspRole, Value } from '../model/gspRole';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspRole');
export interface BackendGspRole {
  getGspRole(id: string): AxiosPromise<GspRole>;
  getGspRoles: (searchParams: SearchParams, contactType: ContactType | undefined | '') => AxiosPromise<Value>;
  deleteGspRole(id: string): AxiosPromise;
  updateGspRole(GspRole: GspRole): AxiosPromise<any>;

  getGspRoleContactTypes(): AxiosPromise<any>;

}

export const defaultBackendGspRole: BackendGspRole = {
  getGspRole(id: string): AxiosPromise<GspRole> {
    // let url = `${URLS.gspRoleOdata}/${id}`;
    let url = `${URLS.gspRole}/${id}`;
    return instance.get<GspRole>(url);
  },
  getGspRoles(searchParams: SearchParams, contactType: ContactType | '' = ''): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspRoleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=RolesData($select=serviceId;$expand=Service($select=name)),contact_types']
      // when try to `$filter` on `contac_types` alwasy get backend error, since Odata badly support search on `many-to-many` relationship (filter on backend)
    );
    logger.log(`getGspRoles${url}`);
    return instance.get<Value>(url, { headers: { contactType: contactType + '' } });
  },

  deleteGspRole(id: string): AxiosPromise {
    logger.debug('deleteGspRole');
    return instance.delete(`${URLS.gspRole}/${id}`);
  },
  updateGspRole(GspRole: GspRole): AxiosPromise<any> {
    logger.debug('updateGspRole');
    return instance.put<GspRole>(`${URLS.gspRole}/update`, gspRole.toAPI(GspRole));
  },

  getGspRoleContactTypes(): AxiosPromise<GspRole> {
    let url = `${URLS.gspRole}/GetRoleContactTypes`;
    return instance.get<any>(url);
  },
};
